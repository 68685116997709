import Cookies from "js-cookie";
import {
  EventBody,
  EventNames,
  ProtoSchema,
  searchParamsStorage,
} from "src/features/marketing/imports";
import { dataMeshTrackEvent } from "src/features/marketing/imports/utils";

const DAYS_TO_EXPIRE = 365;

const PRESENCE_FLAG_VALUE = "true";

export const handleSendMarketingEventOnce = async (
  presenceFlag: string,
  eventName: EventNames,
  body: EventBody,
  ProtoSchema: ProtoSchema
) => {
  const firstLandingCookie = Cookies.get(presenceFlag);
  const firstLandingStored = await searchParamsStorage.getItem(presenceFlag);

  if (Boolean(firstLandingCookie) || Boolean(firstLandingStored)) {
    return;
  }

  await dataMeshTrackEvent({
    eventName,
    ProtoSchema,
    body,
    eventOptions: {
      onSuccessCallback: () => {
        Cookies.set(presenceFlag, PRESENCE_FLAG_VALUE, {
          expires: DAYS_TO_EXPIRE,
          sameSite: "strict",
        });

        searchParamsStorage.setItem(presenceFlag, PRESENCE_FLAG_VALUE);
      },
    },
  });
};
