import { MarketingClientPurchaseEvent } from "generated/proto/MarketingClientPurchaseEvent";
import tangoAnalytics from "src/core/analytics";
import { CURRENCY } from "src/core/analytics/constants";
import { EventNames } from "src/core/analytics/dataMesh/common/enums";
import { dataMeshTrackEvent } from "src/core/analytics/dataMesh/eventMethods/dataMeshTrackEvent";
import {
  OfferEventFields,
  Platforms,
  StorageKeys,
  EventFields as TangoEventFields,
  EventNames as TangoEventNames,
  TransactionMethod,
} from "src/core/analytics/enums";
import { ValueType } from "src/core/analytics/storage/types";
import { analyticsDataType } from "src/core/analytics/types";
import { PersonalOffersConfig } from "src/types/personalOffer";

interface PersonalOfferType {
  [key: string]: unknown;
}

const WEB_CC = "web_cc";

const analyticsPersonalOfferEcommerce = (data: PersonalOfferType): void => {
  tangoAnalytics.then((analytics) => {
    const savedEcommerce = analytics.Session.getItem(StorageKeys.OFFER_DATA);

    analytics.Session.setItem(StorageKeys.OFFER_DATA, {
      [TangoEventFields.ECOMMERCE]: {
        ...savedEcommerce?.[TangoEventFields.ECOMMERCE],
        ...data,
      },
    });
  });
};

const analyticsSelectedPersonalOfferEcommerce = (
  data: PersonalOfferType
): void => {
  tangoAnalytics.then((analytics) => {
    analytics.Session.setItem(StorageKeys.SELECTED_OFFER, data);
  });
};

const analyticsPersonalOffersItems = (data: PersonalOffersConfig): void => {
  const items = data?.pricePoints.reduce<ValueType[]>(
    (accumulator, currentValue, currentIndex) => {
      const webItem = currentValue.offers.find((item) =>
        item.category.includes(WEB_CC)
      );

      const value = {
        [OfferEventFields.ITEM_ID]: webItem?.coins,
        [OfferEventFields.ITEM_NAME]: `${webItem?.coins}_coins`,
        [OfferEventFields.INDEX]: currentIndex,
        [OfferEventFields.ITEM_CATEGORY]: data?.campaignId,
        [OfferEventFields.ITEM_CATEGORY_2]: webItem?.offerSku,
        [OfferEventFields.ITEM_CATEGORY_3]: currentValue.id,
        [OfferEventFields.ITEM_CATEGORY_4]: webItem?.marketOfferId,
        [OfferEventFields.PRICE]: currentValue.price,
        [OfferEventFields.QUANTITY]: 1,
      };

      accumulator.push(value);

      return accumulator;
    },
    []
  );

  analyticsPersonalOfferEcommerce({
    [TangoEventFields.ITEMS]: items,
  });
};

const analyticsPersonalOffer = (data: PersonalOfferType): void => {
  tangoAnalytics.then((analytics) => {
    analytics.Session.setItem(StorageKeys.OFFER_DATA, {
      ...data,
    });
  });
};

const analyticsCompletePersonalOfferData = (transactionId: string): void => {
  tangoAnalytics.then((analytics) => {
    const data = analytics.Session.getItem(StorageKeys.OFFER_DATA);
    const selectedOffer = analytics.Session.getItem(StorageKeys.SELECTED_OFFER);

    data[TangoEventFields.ECOMMERCE][TangoEventFields.ITEMS] = data[
      TangoEventFields.ECOMMERCE
    ][TangoEventFields.ITEMS].filter(
      (offer: PersonalOfferType) =>
        offer[OfferEventFields.ITEM_CATEGORY_4] ===
          selectedOffer.marketOfferId &&
        offer[OfferEventFields.ITEM_CATEGORY_2] === selectedOffer.offerSku
    );

    analytics.Local.setItem(transactionId, {
      [TangoEventFields.TRANSACTION_TYPE]: Platforms.WEB,
      [TangoEventFields.TRANSACTION_METHOD]: TransactionMethod.ONE_TIME,
      ...data,
      [TangoEventFields.ECOMMERCE]: {
        ...data[TangoEventFields.ECOMMERCE],
        [TangoEventFields.TRANSACTION_ID]: transactionId,
      },
    });

    analytics.Session.removeItem(StorageKeys.OFFER_DATA);
  });
};

const analyticsPurchaseEvent = (
  transactionId: string,
  analyticsData: analyticsDataType
): void => {
  const body = {
    transactionId,
    currency: CURRENCY,
    amount: analyticsData.amount_usd,
    amountUsd: analyticsData.local_currency_amount,
    hashedEmail: analyticsData?.hashedEmail,
    hashedPhone: analyticsData?.hashedPhoneNumber,
  };

  dataMeshTrackEvent({
    eventName: EventNames.MARKETING_CLIENT_PURCHASE,
    ProtoSchema: MarketingClientPurchaseEvent,
    body,
  });

  tangoAnalytics.then((analytics) => {
    const eventData = analytics.Local.getItem(transactionId);

    analytics.track(TangoEventNames.PURCHASE_EVENT, eventData);

    analytics.Local.removeItem(transactionId);
  });
};

export {
  analyticsCompletePersonalOfferData,
  analyticsPersonalOffer,
  analyticsPersonalOfferEcommerce,
  analyticsPersonalOffersItems,
  analyticsPurchaseEvent,
  analyticsSelectedPersonalOfferEcommerce,
};
