import Cookies from "js-cookie";
import { AdditionalDataPoints, QueryClickId } from "@analytics/enums";
import { searchParamsStorage } from "@analytics/parseSearchParams/searchParamsStorage";
import {
  FACEBOOK_C_COOKIE_NAME,
  FACEBOOK_P_COOKIE_NAME,
  GA_COOKIE_NAME,
  SNAPCHAT_COOKIE_NAME,
  UTM_COOKIE_NAME_FIRST_TOUCH_PREFIX,
  UTM_COOKIE_NAME_PREFIX,
  UTM_PARAMS,
} from "src/constants";

export default () => {
  const searchParams = new URLSearchParams(window.location.search);
  const marketingFl = searchParams.get(AdditionalDataPoints.MARKETING_FL);
  const referrerSearchParams = document.referrer
    ? new URL(document.referrer)?.searchParams
    : "";

  UTM_PARAMS.forEach((param) => {
    const utmReferrerSearchParam =
      referrerSearchParams instanceof URLSearchParams
        ? referrerSearchParams?.get(param)
        : "";
    const landingSearchParam = searchParams.get(param);
    const utmSearchParam = utmReferrerSearchParam || landingSearchParam;
    if (utmSearchParam) {
      const cookieKey = `${UTM_COOKIE_NAME_PREFIX}${param}`;
      const firstCookieKey = `${UTM_COOKIE_NAME_FIRST_TOUCH_PREFIX}${param}`;

      if (!Cookies.get(firstCookieKey)) {
        Cookies.set(firstCookieKey, utmSearchParam);
      }

      Cookies.set(cookieKey, utmSearchParam);

      searchParamsStorage.setItem(param, utmSearchParam);
    }
  });

  Object.values(QueryClickId).forEach((param) => {
    const value = searchParams.get(param);
    if (value) {
      searchParamsStorage.setItem(param, value);
    }
  });

  Object.values(AdditionalDataPoints).forEach((param) => {
    let value;
    switch (param) {
      case AdditionalDataPoints.SESSION_ID:
        value = Cookies.get(GA_COOKIE_NAME);
        break;
      case AdditionalDataPoints.SNAPCHAT_COOKIE_ID:
        value = Cookies.get(SNAPCHAT_COOKIE_NAME);
        break;
      case AdditionalDataPoints.FACEBOOK_C_COOKIE:
        value = Cookies.get(FACEBOOK_C_COOKIE_NAME);
        break;
      case AdditionalDataPoints.FACEBOOK_P_COOKIE:
        value = Cookies.get(FACEBOOK_P_COOKIE_NAME);
        break;
      case AdditionalDataPoints.LANDING_URL:
        value = window.location.href;
        break;
      case AdditionalDataPoints.REFERRER_URL:
        value = document.referrer;
        break;
      case AdditionalDataPoints.MARKETING_FL:
        value = marketingFl;
        break;
      default:
        value = searchParams.get(param);
    }
    if (value) {
      searchParamsStorage.setItem(param, value);
    }
  });
};
