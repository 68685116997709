import React, {
  MutableRefObject,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import isEqual from "lodash/isEqual";
import { AnyAction } from "redux";
import ChatSpinner from "chat/components/common/Spinner";
import ConversationListItem from "chat/components/conversationsList/ConversationListItem";
import ConversationsListSkeleton from "chat/components/conversationsList/ConversationsListSkeleton";
import { LiveBarCarousel } from "chat/components/liveBar/components/LiveBarCarousel/LiveBarCarousel";
import { CHAT_LIVE_BAR_ELEMENTS_LIST } from "chat/constants";
import { Direction } from "chat/enums";
import { InfiniteScroller } from "chat/imports/components";
import { Breakpoints } from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import {
  RootState,
  batchLoadProfiles,
  getIsMessageRequestEnabled,
  viewerSessionSelectors,
} from "chat/imports/state";
import {
  MessageRequestErrorPlaceholder,
  MessageRequestsEmptyState,
} from "chat/messageRequest/exports/components";
import {
  fetchMessagesRequest,
  messageRequestSelectors,
} from "chat/messageRequest/exports/state";
import { getIsChatLiveBarEnabled } from "chat/soc/chatSoc";
import { fetchConversations } from "chat/state/actionCreators";
import loadUsersListCurrentStreams from "chat/state/flows/loadUsersListCurrentStreams";
import { StoredConversation } from "chat/state/reducer";
import chatSelectors from "chat/state/selectors";
import styles from "./ConversationsList.scss";

interface ConversationsListProps {
  className?: string;
  conversations: StoredConversation[];
  currentConversationId?: string;
  isChatLiveBarEnabled?: boolean;
  isLoadingTriggeredByActions: MutableRefObject<boolean>;
  isRequestsVisible?: boolean;
}

const selector = (state: RootState) => {
  const { loading, error } = chatSelectors.meta(state);
  const { error: errorMessageRequest, loading: loadingMessageRequest } =
    messageRequestSelectors.meta(state);

  return {
    loading,
    error,
    loadingMessageRequest,
    errorMessageRequest,
    canLoadMore: chatSelectors.canLoadMore(state),
    canLoadMoreRequests: messageRequestSelectors.canLoadMore(state),
    broadcasterAccountId: viewerSessionSelectors.getBroadcasterId(state),
    isPlayerVisible: viewerSessionSelectors.isPipPlayerVisible(state),
    isMessageRequestEnabled: getIsMessageRequestEnabled(state),
    chatConversations: chatSelectors.getConversations(state),
    messagesRequests: messageRequestSelectors.getConversations(
      state
    ) as StoredConversation[],
    isChatLiveBarEnabled: getIsChatLiveBarEnabled(state),
  };
};

const ConversationsList: React.FC<ConversationsListProps> = ({
  className,
  isLoadingTriggeredByActions,
  currentConversationId,
  isRequestsVisible,
  conversations,
  isChatLiveBarEnabled,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();
  const [filteredConversations, setFilteredConversations] = useState<
    StoredConversation[]
  >([]);
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  const dataTestId = isRequestsVisible
    ? "message-requests-list"
    : "conversations-list";

  const {
    error,
    loading,
    canLoadMore,
    canLoadMoreRequests,
    errorMessageRequest,
    broadcasterAccountId,
    isPlayerVisible,
    loadingMessageRequest,
    isMessageRequestEnabled,
    chatConversations,
    messagesRequests,
  } = useSelector(selector, shallowEqual);

  const isShowRequestsPlaceholder =
    !conversations.length &&
    isRequestsVisible &&
    isDesktop &&
    !loadingMessageRequest &&
    !errorMessageRequest;
  const iShowRequestsErrorPlaceholder =
    errorMessageRequest && isRequestsVisible && !loadingMessageRequest;

  const refresh = useCallback(async () => {
    isLoadingTriggeredByActions.current = true;
    await dispatch(
      fetchConversations({
        direction: Direction.FORWARD,
        last_update_request_timestamp: 0,
        include_group_members: true,
        include_messages: true,
        include_group_info: true,
        include_account_info: true,
      })
    );
    await dispatch(
      fetchMessagesRequest({
        direction: Direction.FORWARD,
        last_update_request_timestamp: 0,
        include_group_members: true,
        include_messages: true,
        include_group_info: true,
        include_account_info: true,
      })
    );
    isLoadingTriggeredByActions.current = false;
  }, []);

  const chatLastConversationTs = useMemo(() => {
    const conversationsWithMessages = conversations.filter(
      (conversation) => !!conversation.last_message_ts
    );

    return conversationsWithMessages[conversationsWithMessages.length - 1]
      ?.last_message_ts;
  }, [conversations]);

  const messagesRequestsLastConversationTs = useMemo(() => {
    const conversationsWithMessages = messagesRequests.filter(
      (conversation) => !!conversation.last_message_ts
    );

    return conversationsWithMessages[conversationsWithMessages.length - 1]
      ?.last_message_ts;
  }, [messagesRequests]);

  const loadMore = useCallback(async () => {
    isLoadingTriggeredByActions.current = true;
    await dispatch(
      fetchConversations({
        direction: Direction.REVERSE,
        last_update_request_timestamp: chatLastConversationTs,
        include_group_members: true,
        include_messages: true,
        include_group_info: true,
        include_account_info: true,
      })
    );
    isLoadingTriggeredByActions.current = false;
  }, [chatLastConversationTs, conversations]);

  useEffect(() => {
    if (loading || loadingMessageRequest) {
      return;
    }

    const allConversations = [...messagesRequests, ...chatConversations];

    const list =
      isPlayerVisible && broadcasterAccountId
        ? allConversations.filter(
            (conversation) =>
              conversation.account_info?.account_id !== broadcasterAccountId
          )
        : allConversations;

    if (!isEqual(list, filteredConversations)) {
      dispatch(loadUsersListCurrentStreams(list));
      setFilteredConversations(list);
    }
  }, [
    loading,
    filteredConversations,
    loadingMessageRequest,
    messagesRequests,
    chatConversations,
    broadcasterAccountId,
    isPlayerVisible,
    dispatch,
  ]);

  const loadMoreMessageRequest = useCallback(async () => {
    isLoadingTriggeredByActions.current = true;
    await dispatch(
      fetchMessagesRequest({
        direction: Direction.REVERSE,
        last_update_request_timestamp: messagesRequestsLastConversationTs,
        include_group_members: true,
        include_messages: true,
        include_group_info: true,
        include_account_info: true,
      }) as unknown as AnyAction
    );
    isLoadingTriggeredByActions.current = false;
  }, [
    dispatch,
    isLoadingTriggeredByActions,
    messagesRequestsLastConversationTs,
    conversations,
  ]);

  useEffect(() => {
    dispatch(
      batchLoadProfiles({
        ids: messagesRequests.map(
          (conversation) => conversation?.account_info?.account_id
        ),
        loadOnlyIfMissing: true,
      }) as unknown as AnyAction
    );
  }, [messagesRequests]);

  const isLoading = useMemo(
    () =>
      (loading || loadingMessageRequest) && isLoadingTriggeredByActions.current,

    [loading, loadingMessageRequest, isLoadingTriggeredByActions]
  );

  if (isShowRequestsPlaceholder) {
    return <MessageRequestsEmptyState breakpoint={breakpoint} />;
  }

  if (iShowRequestsErrorPlaceholder) {
    return <MessageRequestErrorPlaceholder onClick={refresh} />;
  }

  return (loading || loadingMessageRequest) && !conversations.length ? (
    <ConversationsListSkeleton
      isChatLiveBarEnabled={isChatLiveBarEnabled}
      withHeader={isMessageRequestEnabled}
    />
  ) : (
    <>
      <InfiniteScroller
        className={classnames(
          isChatLiveBarEnabled && styles.isShowLiveBarCarousel,
          styles.root,
          className,
          styles[breakpoint]
        )}
        refresh={refresh}
        loadMore={isRequestsVisible ? loadMoreMessageRequest : loadMore}
        isLoading={
          (loading || loadingMessageRequest) &&
          isLoadingTriggeredByActions.current
        }
        canLoadMore={isRequestsVisible ? canLoadMoreRequests : canLoadMore}
        hasLoadError={isRequestsVisible ? !!errorMessageRequest : !!error}
        data-testid={dataTestId}
        showSpinner={false}
      >
        {isChatLiveBarEnabled && (
          <>
            <LiveBarCarousel items={CHAT_LIVE_BAR_ELEMENTS_LIST} />
          </>
        )}
        {conversations.map((conversation) => (
          <ConversationListItem
            key={conversation.conversation_id}
            isCurrent={currentConversationId === conversation.conversation_id}
            conversation={conversation}
            isRequestsVisible={isRequestsVisible}
          />
        ))}
        {isLoading && conversations.length && (
          <ChatSpinner className={styles.spinner} />
        )}
      </InfiniteScroller>
    </>
  );
};

export default memo(ConversationsList);
