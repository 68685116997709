import debounce from "lodash.debounce";
import { SendEventUtilOptions } from "src/core/analytics/dataMesh/common/types";
import { getEventBuffer } from "src/core/analytics/dataMesh/utils/bufferMethods/getEventBuffer";
import { sendDataMeshEvent } from "src/core/analytics/dataMesh/utils/sendDataMeshEvent";
import { getDataMeshEventBufferFlushTime } from "src/core/analytics/imports/environment";

export const checkIfEventBufferIsStale = debounce(
  async (options?: SendEventUtilOptions) => {
    const eventsBuffer = await getEventBuffer();

    if (eventsBuffer.length) {
      sendDataMeshEvent(eventsBuffer, options);
    }
  },
  getDataMeshEventBufferFlushTime()
);
