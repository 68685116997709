/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useEffect, useState } from "react";
import logger from "utils/logger";

const VIDEO_ELEMENT_STATE_EVENTS: (keyof HTMLVideoElementEventMap)[] = [
  "play",
  "abort",
  "pause",
  "ended",
  "error",
  "emptied",
] as const;

export type PlaybackState = ((
  video: HTMLVideoElement,
  eventName: keyof HTMLVideoElementEventMap
) => PlaybackState) & { readonly __brand: unique symbol };

/// Media is not actively played and user interaction is required to start to play again.
export const PausedState = ((
  _video: HTMLVideoElement,
  eventName: keyof HTMLVideoElementEventMap
) => {
  switch (eventName) {
    case "play":
      return PlayingState;
    case "abort":
      return IdleState;
    case "ended":
      return IdleState;
    case "emptied":
      return IdleState;
    case "error":
      return IdleState;
    default:
      logger.log(`[PausedState]: ignore '${eventName}' event`);

      return PausedState;
  }
}) as PlaybackState;

/// Media is actively played no user interaction required
export const PlayingState = ((
  _video: HTMLVideoElement,
  eventName: keyof HTMLVideoElementEventMap
) => {
  switch (eventName) {
    case "pause":
      return PausedState;
    case "abort":
      return IdleState;
    case "ended":
      return IdleState;
    case "emptied":
      return IdleState;
    case "error":
      return IdleState;
    default:
      logger.log(`[PlayingState]: ignore '${eventName}' event`);

      return PlayingState;
  }
}) as PlaybackState;

/// Media is not actively playing, no user interaction is required to start playback.
export const IdleState = ((
  _video: HTMLVideoElement,
  eventName: keyof HTMLVideoElementEventMap
) => {
  switch (eventName) {
    case "play":
      return PlayingState;
    default:
      logger.log(`[IdleState]: ignore '${eventName}' event`);

      return IdleState;
  }
}) as PlaybackState;

export type DescribedState = "error" | "idle" | "paused" | "playing";

export const describeState = (state: PlaybackState): DescribedState => {
  if (state === PlayingState) {
    return "playing";
  }
  if (state === PausedState) {
    return "paused";
  }
  if (state === IdleState) {
    return "idle";
  }

  return "error";
};

const useVideoPlaybackState = (
  videoRef: React.RefObject<HTMLVideoElement | null>
) => {
  // NOTE: Do not refactor `() => IdleState` to `IdleState`, because otherwise React start to interpret IdleState as initializer function
  const [playbackState, setPlaybackState] = useState<PlaybackState>(
    () => IdleState
  );

  useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    const traceVideoEvent = (event: Event) => {
      logger.log(`HTMLVideoElement: event '${event.type}' happen`);
      if (event.target instanceof HTMLVideoElement) {
        const eventTarget = event.target;
        const eventName = event.type as keyof HTMLVideoElementEventMap;

        setPlaybackState((playbackState: PlaybackState) => {
          const newState = playbackState(eventTarget, eventName);
          if (playbackState !== newState) {
            logger.log(
              `HTMLVideoElement: switching playback state: ${describeState(playbackState)} => ${describeState(newState)}`
            );
          }

          return newState;
        });
      }
    };

    VIDEO_ELEMENT_STATE_EVENTS.forEach((eventName) => {
      video.addEventListener(eventName, traceVideoEvent);
    });

    return () => {
      VIDEO_ELEMENT_STATE_EVENTS.forEach((eventName) => {
        video.removeEventListener(eventName, traceVideoEvent);
      });
    };
  }, [videoRef]);

  return { playbackState };
};

export default useVideoPlaybackState;
