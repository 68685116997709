import { StorageKeys } from "src/core/analytics/dataMesh/common/enums";
import {
  DataMeshEventPayload,
  SendEventUtilOptions,
} from "src/core/analytics/dataMesh/common/types";
import { checkIfEventBufferIsStale } from "src/core/analytics/dataMesh/utils/bufferMethods/checkIfEventBufferIsStale";
import { checkIsEventBufferSizeExceeded } from "src/core/analytics/dataMesh/utils/bufferMethods/checkIsEventBufferSizeExceeded";
import { getEventBuffer } from "src/core/analytics/dataMesh/utils/bufferMethods/getEventBuffer";
import { dataMeshParamsStorage } from "src/core/analytics/dataMesh/utils/dbMethods/dataMeshParamsStorage";
import { sendDataMeshEvent } from "src/core/analytics/dataMesh/utils/sendDataMeshEvent";

export const addEventToBuffer = async (
  event: DataMeshEventPayload,
  options?: SendEventUtilOptions
) => {
  const eventBuffer = await getEventBuffer();

  checkIfEventBufferIsStale(options);

  const isBufferFlushReady = await checkIsEventBufferSizeExceeded();

  if (isBufferFlushReady) {
    sendDataMeshEvent([...eventBuffer, event], options);

    return;
  }

  await dataMeshParamsStorage.setItem(
    StorageKeys.SESSION_BUFFER,
    JSON.stringify([...eventBuffer, event])
  );
};
