import React, { FC } from "react";
import classnames from "classnames";
import LiveBarCarouselItem, {
  LiveBarCarouselItemData,
} from "chat/components/liveBar/components/LiveBarCarouselItem/LiveBarCarouselItem";
import styles from "./LiveBarCarouselTrack.scss";

interface LiveBarCarouselTrackProps {
  items: Array<LiveBarCarouselItemData>;
}

export const LiveBarCarouselTrack: FC<LiveBarCarouselTrackProps> = ({
  items,
}) => (
  <div className={classnames(styles.root)}>
    {items.map((item) => (
      <LiveBarCarouselItem key={item.id} data={item} />
    ))}
  </div>
);
