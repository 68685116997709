import { useEffect, useState } from "react";

const useDocumentVisibilityState = () => {
  const [visibilityState, setVisibilityState] = useState(
    document.visibilityState
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisibilityState(document.visibilityState);
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return { visibilityState };
};

export default useDocumentVisibilityState;
