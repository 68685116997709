import React, { MutableRefObject, forwardRef, memo } from "react";
import classnames from "classnames";
import ConversationsList from "chat/components/conversationsList/ConversationsList";
import { Breakpoints } from "chat/imports/constants";
import { StoredConversation } from "chat/state/reducer";
import styles from "./ChatContent.scss";

interface ChatContentProps {
  breakpoint: Breakpoints;
  conversations: StoredConversation[];
  currentConversationId: string | undefined;
  isChatLiveBarEnabled: boolean;
  isLoadingTriggeredByActions: MutableRefObject<boolean>;
  isRequestsVisible: boolean | undefined;
}

const ChatContent = forwardRef<HTMLDivElement, ChatContentProps>(
  (
    {
      isRequestsVisible,
      conversations,
      currentConversationId,
      isLoadingTriggeredByActions,
      breakpoint,
      isChatLiveBarEnabled,
    },
    ref
  ) => {
    const isDesktop = breakpoint === Breakpoints.DESKTOP;

    return (
      <div
        className={classnames(styles.chatContainer, styles[breakpoint], {
          [styles.isRequestsVisible]: isRequestsVisible,
          [styles.chatContainerOffset]: !isDesktop && isRequestsVisible,
          [styles.isNotEmpty]: conversations.length > 0,
        })}
        ref={ref}
      >
        <div className={styles.sidebar}>
          <ConversationsList
            isLoadingTriggeredByActions={isLoadingTriggeredByActions}
            currentConversationId={currentConversationId}
            conversations={conversations}
            isRequestsVisible={isRequestsVisible}
            isChatLiveBarEnabled={isChatLiveBarEnabled}
          />
        </div>
        <div className={styles.messagesRequests}>
          <ConversationsList
            isLoadingTriggeredByActions={isLoadingTriggeredByActions}
            currentConversationId={currentConversationId}
            conversations={conversations}
            isRequestsVisible={isRequestsVisible}
            isChatLiveBarEnabled={isChatLiveBarEnabled}
          />
        </div>
      </div>
    );
  }
);

ChatContent.displayName = "ChatContent";

const MemoizedChatContent = memo(ChatContent);

export { MemoizedChatContent };
