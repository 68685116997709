import { datadogLogs } from "@datadog/browser-logs";
import { MarketingClientFirstLandingEvent } from "generated/proto/MarketingClientFirstLandingEventModel";
import {
  EventNames,
  FIRST_LANDING,
  QueryClickId,
  UtmParams,
} from "src/features/marketing/imports";
import { handleSendMarketingEventOnce } from "src/features/marketing/utils/handleSendMarketingEventOnce";

export const handleSendFirstLandingEvent = async () => {
  try {
    const searchParams = new URLSearchParams(window.location.search);

    const clickId = Object.values(QueryClickId)
      .map((param) => searchParams.get(param))
      .filter(Boolean)
      .join(",");

    const body = {
      landingUrl: window.location.href,
      referrerUrl: document.referrer || "",
      utmSource: searchParams.get(UtmParams.UTM_SOURCE),
      utmMedium: searchParams.get(UtmParams.UTM_MEDIUM),
      utmCampaign: searchParams.get(UtmParams.UTM_CAMPAIGN),
      clickId,
    };

    await handleSendMarketingEventOnce(
      FIRST_LANDING,
      EventNames.MARKETING_CLIENT_FIRST_LANDING,
      body,
      MarketingClientFirstLandingEvent
    );
  } catch (e) {
    const error = e as Error;
    datadogLogs.logger.warn(
      error?.message,
      { kind: "marketing_first_landing" },
      error
    );
  }
};
