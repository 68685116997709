import React, { FC, memo } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { ReactComponent as LiveBadge } from "chat/assets/liveBadge.svg";
import { ReactComponent as PremiumBadge } from "chat/assets/premiumBadge.svg";
import { Avatar } from "chat/imports/components";
import { makeLinkToLiveStream } from "chat/imports/utils";
import { BadgeType } from "chat/types";
import { AvatarSize, VipLabel } from "src/enums";
import styles from "./LiveBarCarouselItem.scss";

export interface LiveBarCarouselItemData {
  avatarUrl: string;
  badgeLabel?: string;
  badgeType?: BadgeType;
  id: string;
  name: string;
  streamId?: string;
  vipLabel?: VipLabel;
}

interface LiveBarCarouselItemProps {
  data: LiveBarCarouselItemData;
}

const LiveBarCarouselItem: FC<LiveBarCarouselItemProps> = ({ data }) => {
  const { streamId, name, avatarUrl, badgeType = BadgeType.LIVE } = data;

  const history = useHistory();

  const handleClick = () => {
    const streamLink = makeLinkToLiveStream(streamId);
    history.push(streamLink);
  };

  return (
    <div
      className={classnames(styles.root)}
      onClick={handleClick}
      role="button"
      tabIndex={0}
    >
      {badgeType === BadgeType.LIVE && (
        <LiveBadge className={styles.avatarLiveBadge} />
      )}
      {badgeType === BadgeType.PREMIUM && (
        <PremiumBadge className={styles.avatarLiveBadge} />
      )}

      <Avatar
        imageUrl={avatarUrl}
        className={styles.avatar}
        size={AvatarSize.SMALL_PLUS}
        data-testid="live-bar-carousel-item-avatar"
        vipLabel={data.vipLabel}
      />

      <div className={styles.userName}>{name}</div>
    </div>
  );
};

export default memo(LiveBarCarouselItem);
