import React, { useCallback } from "react";
import classnames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { LiveBarCarouselItemData } from "chat/components/liveBar/components/LiveBarCarouselItem/LiveBarCarouselItem";
import { LiveBarCarouselTrack } from "chat/components/liveBar/components/LiveBarCarouselTrack/LiveBarCarouselTrack";
import { CHAT_LIVE_BAR_ELEMENTS_COUNT } from "chat/constants";
import { useEmblaScrollState } from "chat/hooks/useEmblaScrollState";
import { ChevronLeftIcon, ChevronRightIcon } from "chat/imports/assets";
import { Button } from "chat/imports/components";
import { ButtonSize, ButtonVariant } from "chat/imports/types";
import styles from "./LiveBarCarousel.scss";

interface LiveBarCarouselProps {
  className?: string;
  items: Array<LiveBarCarouselItemData>;
}

export const LiveBarCarousel: React.FC<LiveBarCarouselProps> = ({
  items,
  className,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    loop: false,
    skipSnaps: true,
  });

  const handleNextClick = useCallback(() => {
    if (emblaApi) {
      const currentIndex = emblaApi.selectedScrollSnap();
      const totalSnaps = emblaApi.scrollSnapList().length;
      const nextIndex = Math.min(currentIndex + 2, totalSnaps - 1);
      emblaApi.scrollTo(nextIndex);
    }
  }, [emblaApi]);

  const handlePrevClick = useCallback(() => {
    if (emblaApi) {
      const currentIndex = emblaApi.selectedScrollSnap();
      const prevIndex = Math.max(currentIndex - 2, 0);
      emblaApi.scrollTo(prevIndex);
    }
  }, [emblaApi]);

  const { canScrollPrev, canScrollNext } = useEmblaScrollState(emblaApi);

  return (
    <div className={classnames(styles.liveBarCarouselContainer, className)}>
      <div
        className={classnames(styles.liveBarCarouselWrapper, {
          [styles.canScrollPrev]: true,
          [styles.isButtonHidden]:
            items.length < CHAT_LIVE_BAR_ELEMENTS_COUNT + 1,
        })}
        ref={emblaRef}
      >
        <LiveBarCarouselTrack items={items} />
      </div>

      {items.length > CHAT_LIVE_BAR_ELEMENTS_COUNT && (
        <>
          <Button
            className={styles.prevSlideButton}
            size={ButtonSize.CIRCLE_MEDIUM_40}
            variant={ButtonVariant.SECONDARY}
            onClick={handlePrevClick}
            disabled={!canScrollPrev}
            dataTestId="live-bar-prev-carousel-arrow"
          >
            <ChevronLeftIcon />
          </Button>
          <Button
            className={styles.nextSlideButton}
            size={ButtonSize.CIRCLE_MEDIUM_40}
            variant={ButtonVariant.SECONDARY}
            onClick={handleNextClick}
            disabled={!canScrollNext}
            dataTestId="live-bar-next-carousel-arrow"
          >
            <ChevronRightIcon />
          </Button>
        </>
      )}
    </div>
  );
};
