/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.MarketingClientFirstLandingEventModel || ($protobuf.roots.MarketingClientFirstLandingEventModel = {});

export const MarketingClientFirstLandingEvent = $root.MarketingClientFirstLandingEvent = (() => {

    function MarketingClientFirstLandingEvent(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MarketingClientFirstLandingEvent.prototype.landingUrl = null;
    MarketingClientFirstLandingEvent.prototype.referrerUrl = null;
    MarketingClientFirstLandingEvent.prototype.utmSource = null;
    MarketingClientFirstLandingEvent.prototype.utmMedium = null;
    MarketingClientFirstLandingEvent.prototype.utmCampaign = null;
    MarketingClientFirstLandingEvent.prototype.clickId = null;

    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientFirstLandingEvent.prototype, "_landingUrl", {
        get: $util.oneOfGetter($oneOfFields = ["landingUrl"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientFirstLandingEvent.prototype, "_referrerUrl", {
        get: $util.oneOfGetter($oneOfFields = ["referrerUrl"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientFirstLandingEvent.prototype, "_utmSource", {
        get: $util.oneOfGetter($oneOfFields = ["utmSource"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientFirstLandingEvent.prototype, "_utmMedium", {
        get: $util.oneOfGetter($oneOfFields = ["utmMedium"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientFirstLandingEvent.prototype, "_utmCampaign", {
        get: $util.oneOfGetter($oneOfFields = ["utmCampaign"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientFirstLandingEvent.prototype, "_clickId", {
        get: $util.oneOfGetter($oneOfFields = ["clickId"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    MarketingClientFirstLandingEvent.create = function create(properties) {
        return new MarketingClientFirstLandingEvent(properties);
    };

    MarketingClientFirstLandingEvent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.landingUrl != null && Object.hasOwnProperty.call(message, "landingUrl"))
            writer.uint32(10).string(message.landingUrl);
        if (message.referrerUrl != null && Object.hasOwnProperty.call(message, "referrerUrl"))
            writer.uint32(18).string(message.referrerUrl);
        if (message.utmSource != null && Object.hasOwnProperty.call(message, "utmSource"))
            writer.uint32(26).string(message.utmSource);
        if (message.utmMedium != null && Object.hasOwnProperty.call(message, "utmMedium"))
            writer.uint32(34).string(message.utmMedium);
        if (message.utmCampaign != null && Object.hasOwnProperty.call(message, "utmCampaign"))
            writer.uint32(42).string(message.utmCampaign);
        if (message.clickId != null && Object.hasOwnProperty.call(message, "clickId"))
            writer.uint32(50).string(message.clickId);
        return writer;
    };

    MarketingClientFirstLandingEvent.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MarketingClientFirstLandingEvent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MarketingClientFirstLandingEvent();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.landingUrl = reader.string();
                    break;
                }
            case 2: {
                    message.referrerUrl = reader.string();
                    break;
                }
            case 3: {
                    message.utmSource = reader.string();
                    break;
                }
            case 4: {
                    message.utmMedium = reader.string();
                    break;
                }
            case 5: {
                    message.utmCampaign = reader.string();
                    break;
                }
            case 6: {
                    message.clickId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    MarketingClientFirstLandingEvent.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MarketingClientFirstLandingEvent.fromObject = function fromObject(object) {
        if (object instanceof $root.MarketingClientFirstLandingEvent)
            return object;
        let message = new $root.MarketingClientFirstLandingEvent();
        if (object.landingUrl != null)
            message.landingUrl = String(object.landingUrl);
        if (object.referrerUrl != null)
            message.referrerUrl = String(object.referrerUrl);
        if (object.utmSource != null)
            message.utmSource = String(object.utmSource);
        if (object.utmMedium != null)
            message.utmMedium = String(object.utmMedium);
        if (object.utmCampaign != null)
            message.utmCampaign = String(object.utmCampaign);
        if (object.clickId != null)
            message.clickId = String(object.clickId);
        return message;
    };

    MarketingClientFirstLandingEvent.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.landingUrl != null && message.hasOwnProperty("landingUrl")) {
            object.landingUrl = message.landingUrl;
            if (options.oneofs)
                object._landingUrl = "landingUrl";
        }
        if (message.referrerUrl != null && message.hasOwnProperty("referrerUrl")) {
            object.referrerUrl = message.referrerUrl;
            if (options.oneofs)
                object._referrerUrl = "referrerUrl";
        }
        if (message.utmSource != null && message.hasOwnProperty("utmSource")) {
            object.utmSource = message.utmSource;
            if (options.oneofs)
                object._utmSource = "utmSource";
        }
        if (message.utmMedium != null && message.hasOwnProperty("utmMedium")) {
            object.utmMedium = message.utmMedium;
            if (options.oneofs)
                object._utmMedium = "utmMedium";
        }
        if (message.utmCampaign != null && message.hasOwnProperty("utmCampaign")) {
            object.utmCampaign = message.utmCampaign;
            if (options.oneofs)
                object._utmCampaign = "utmCampaign";
        }
        if (message.clickId != null && message.hasOwnProperty("clickId")) {
            object.clickId = message.clickId;
            if (options.oneofs)
                object._clickId = "clickId";
        }
        return object;
    };

    MarketingClientFirstLandingEvent.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MarketingClientFirstLandingEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MarketingClientFirstLandingEvent";
    };

    return MarketingClientFirstLandingEvent;
})();

export { $root as default };
