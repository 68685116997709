import { datadogLogs } from "@datadog/browser-logs";
import { MarketingClientFirstVisitEvent } from "generated/proto/MarketingClientFirstVisitEventModel";
import {
  EventBody,
  EventNames,
  FIRST_VISIT,
} from "src/features/marketing/imports";
import { handleSendMarketingEventOnce } from "src/features/marketing/utils/handleSendMarketingEventOnce";

export const handleSendFirstVisitEvent = async (body: EventBody) => {
  try {
    await handleSendMarketingEventOnce(
      FIRST_VISIT,
      EventNames.MARKETING_CLIENT_FIRST_VISIT,
      body,
      MarketingClientFirstVisitEvent
    );
  } catch (e) {
    const error = e as Error;
    datadogLogs.logger.warn(
      error?.message,
      { kind: "marketing_first_visit" },
      error
    );
  }
};
