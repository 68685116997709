import { v4 as uuidv4 } from "uuid";
import {
  EventNames,
  StorageKeys,
} from "src/core/analytics/dataMesh/common/enums";
import {
  EventBody,
  ProtoSchema,
  SendEventUtilOptions,
} from "src/core/analytics/dataMesh/common/types";
import { startNewSession } from "src/core/analytics/dataMesh/eventMethods/startNewSession";
import { addEventToBuffer } from "src/core/analytics/dataMesh/utils/bufferMethods/addEventToBuffer";
import { getSessionStartParams } from "src/core/analytics/dataMesh/utils/dbMethods/getSessionStartParams";
import { sendDataMeshEvent } from "src/core/analytics/dataMesh/utils/sendDataMeshEvent";
import { getAnalyticsPluginEnabled } from "src/core/analytics/dataMesh/utils/store/getStoreEntity";
import { getDataMeshEventWhitelist } from "src/core/analytics/imports/environment";
import { currentTimeMillis } from "src/core/analytics/imports/utils";

interface DataMeshTrackEventParameters {
  screenName: string;
  screenState: string;
}

const LOCK_EVENT_TIMEOUT = 500;

interface DataMeshTrackEventPayload {
  ProtoSchema: ProtoSchema;
  body: EventBody;
  eventName: EventNames;
  eventOptions?: SendEventUtilOptions;
  eventParams?: Partial<DataMeshTrackEventParameters>;
}

export const dataMeshTrackEvent = async ({
  eventName,
  ProtoSchema,
  body,
  eventParams,
  eventOptions,
}: DataMeshTrackEventPayload) => {
  const payloadKey = JSON.stringify(body);
  const payload = ProtoSchema.encode(body).finish();

  const processEvent = () =>
    new Promise<void>((resolve) => {
      setTimeout(async () => {
        const isAnalyticsPluginGatewayEnabled = getAnalyticsPluginEnabled();
        if (!isAnalyticsPluginGatewayEnabled) {
          return;
        }
        let clientSessionId = (await getSessionStartParams())[
          StorageKeys.SESSION_START_ID
        ];

        if (!clientSessionId) {
          await startNewSession();
        }
        clientSessionId = (await getSessionStartParams())[
          StorageKeys.SESSION_START_ID
        ];
        const timestamp = currentTimeMillis();
        const eventUid = uuidv4();

        const whitelistEvents = getDataMeshEventWhitelist();

        if (whitelistEvents.includes(eventName)) {
          sendDataMeshEvent(
            [
              {
                eventName,
                payload,
                eventUid,
                clientSessionId,
                eventParams,
                timestamp,
              },
            ],
            eventOptions
          );
        } else {
          addEventToBuffer(
            {
              eventName,
              payload,
              eventUid,
              clientSessionId,
              eventParams,
              timestamp,
            },
            eventOptions
          );
        }
        resolve();
      }, LOCK_EVENT_TIMEOUT);
    });

  if (navigator?.locks) {
    await navigator.locks.request(
      payloadKey,
      { ifAvailable: true },
      async (lock) => {
        if (lock) {
          await processEvent();
        }
      }
    );
  } else {
    processEvent();
  }
};
