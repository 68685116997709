import { CHAT_EVENTS_TYPES, REACTION } from "chat/enums";
import { ContentType } from "chat/premiumMessage/types";
import { BadgeType, ChatMessageSentFlags, MessageType } from "chat/types";
import { VipLabel } from "src/enums";

export const CHAT_MAX_TEXT_LENGTH = 3000;
export const CHAT_OLDER_MESSAGES_PAGE_SIZE = 20;
export const CHAT_CONVERSATIONS_PAGE_SIZE = 20;
export const CHAT_CONVERSATIONS_LIST_DEFAULT_MESSAGES_LIMIT = 1;
export const CHAT_CONVERSATIONS_LIST_REFRESH_MESSAGES_LIMIT = 1;
export const CHAT_CONVERSATIONS_LIST_REACTIONS_LIMIT = 10;
export const CHAT_CONVERSATIONS_LIST_REACTIONS_START_TS = 0;

export const CHAT_SOURCE_CHAT_LIST = 2;
export const GIFT_SOURCE_PREMIUM_MESSAGE = "Premium Message";

export const ACME_READ_RECEIPT_IDENTIFICATOR = "tc:readreceipt";

export const ACME_READ_MESSAGE_EVENT_IDENTIFICATOR = "tc:readmessageevent";

export const ACME_CHAT_EVENT_IDENTIFICATOR = "tc:chatevents";

export const ACME_CHAT_REACTION_IDENTIFICATOR = "chat_reactions:reactions";
export const ACME_CHAT_SELFREACTION_IDENTIFICATOR =
  "chat_reactions:selfreactions";

export const ALLOWED_CHAT_EVENTS = [
  CHAT_EVENTS_TYPES.DELETE_MESSAGE_FOR_ALL,
  CHAT_EVENTS_TYPES.DELETE_MESSAGE_FOR_ME,
  CHAT_EVENTS_TYPES.EDIT_MESSAGE,
  CHAT_EVENTS_TYPES.CHAT_MUTED,
  CHAT_EVENTS_TYPES.CHAT_UNMUTED,
  CHAT_EVENTS_TYPES.DELETE_CHAT_FOR_ME,
];

export const MAX_PIN_CHATS_NUMBER = 10;

export const systemMessageTypes = [
  MessageType.GROUP_MEMBER_JOIN,
  MessageType.GROUP_MEMBER_LEAVE,
  MessageType.GROUP_NAME_CHANGE,
  MessageType.LIKE_MESSAGE,
  MessageType.SCREENSHOT_INFO_MESSAGE,
  MessageType.VIDEO_SAVED_INFO_MESSAGE,
  MessageType.PHOTO_SAVED_INFO_MESSAGE,
];

export const ignoredMessagesTypes = [MessageType.GROUP_UPDATE];

export const ChatPremiumMessageTypeToFlagMap = {
  [ContentType.PHOTO]: ChatMessageSentFlags.IMAGE,
  [ContentType.VIDEO]: ChatMessageSentFlags.VIDEO,
};

export const CHAT_EVENTS_DEFAULT_TIMESTAMP = 0;

export const POSITION = {
  BOTTOM: "BOTTOM",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  TOP: "TOP",
};

export const REACTIONS_LIST: Array<REACTION> = [
  REACTION.HEART,
  REACTION.FIRE,
  REACTION.LAUGH,
  REACTION.CRYING,
  REACTION.HANDS,
  REACTION.THUMB_UP,
];

export const CHAT_ANALYTICS_PARAMS_EMOJI_TARGET = "react_emoji";
export const CHAT_ANALYTICS_PARAMS_CLICK_ACTION = "click";
export const CHAT_ANALYTICS_PARAMS_CHAT_SOURCE_ID = "chat";
export const CHAT_ANALYTICS_PARAMS_RECIPIENT_ACCOUNT_ID = "account_id";

export const CHAT_DEFAULT_LIVE_BAR_LIMIT = 12;
export const CHAT_DEFAULT_MODERATION_LEVEL = 4;

export const CHAT_LIVE_BAR_ELEMENTS_COUNT = 4;

export const CHAT_LIVE_BAR_ELEMENTS_LIST = [
  // TODO: https://tango-me.atlassian.net/browse/WEB-9521 add real item list instead of mocked one
  {
    id: "1",
    name: "🌸🌸Anastasiia",
    avatarUrl:
      "https://cget.tango.me/contentserver/download/AAFn3B2VAABs0-FtJjvgkpi2AAAAAMasJJM/OEpcsnU0/thumbnail",
    badgeType: BadgeType.LIVE,
    vipLabel: VipLabel.GOLD,
    streamId: "BL9atY7ieAagd1ar8xZWLQ",
  },
  {
    id: "2",
    name: "Lucky man",
    avatarUrl:
      "https://cget.tango.me/contentserver/download/AAFn3B2VAABs0-FtJjvgkpi2AAAAAMasJJM/OEpcsnU0/thumbnail",
    badgeType: BadgeType.LIVE,
    vipLabel: VipLabel.ROYAL,
    streamId: "BL9atY7ieAagd1ar8xZWLQ",
  },
  {
    id: "3",
    name: "Light weight",
    avatarUrl:
      "https://cget.tango.me/contentserver/download/AAFn3B2VAABs0-FtJjvgkpi2AAAAAMasJJM/OEpcsnU0/thumbnail",
    badgeType: BadgeType.PREMIUM,
    vipLabel: VipLabel.GOLD,
    streamId: "BL9atY7ieAagd1ar8xZWLQ",
  },
  {
    id: "4",
    name: "HaBibi",
    avatarUrl:
      "https://cget.tango.me/contentserver/download/AAFn3B2VAABs0-FtJjvgkpi2AAAAAMasJJM/OEpcsnU0/thumbnail",
    badgeType: BadgeType.LIVE,
    streamId: "BL9atY7ieAagd1ar8xZWLQ",
  },
  {
    id: "5",
    name: "Omg what",
    avatarUrl:
      "https://cget.tango.me/contentserver/download/AAFn3B2VAABs0-FtJjvgkpi2AAAAAMasJJM/OEpcsnU0/thumbnail",
    badgeType: BadgeType.NONE,
    vipLabel: VipLabel.OLYMPUS,
    streamId: "BL9atY7ieAagd1ar8xZWLQ",
  },
  {
    id: "6",
    name: "🌸🌸Aaaa",
    avatarUrl:
      "https://cget.tango.me/contentserver/download/AAFn3B2VAABs0-FtJjvgkpi2AAAAAMasJJM/OEpcsnU0/thumbnail",
    badgeType: BadgeType.LIVE,
    vipLabel: VipLabel.DIAMOND,
    streamId: "BL9atY7ieAagd1ar8xZWLQ",
  },
  {
    id: "7",
    name: "Lucky man",
    avatarUrl:
      "https://cget.tango.me/contentserver/download/AAFn3B2VAABs0-FtJjvgkpi2AAAAAMasJJM/OEpcsnU0/thumbnail",
    badgeType: BadgeType.LIVE,
    streamId: "BL9atY7ieAagd1ar8xZWLQ",
  },
  {
    id: "8",
    name: "Light weight",
    avatarUrl:
      "https://cget.tango.me/contentserver/download/AAFn3B2VAABs0-FtJjvgkpi2AAAAAMasJJM/OEpcsnU0/thumbnail",
    badgeType: BadgeType.PREMIUM,
    vipLabel: VipLabel.BRONZE,
    streamId: "BL9atY7ieAagd1ar8xZWLQ",
  },
  {
    id: "9",
    name: "HaBibi",
    avatarUrl:
      "https://cget.tango.me/contentserver/download/AAFn3B2VAABs0-FtJjvgkpi2AAAAAMasJJM/OEpcsnU0/thumbnail",
    badgeType: BadgeType.LIVE,
    streamId: "BL9atY7ieAagd1ar8xZWLQ",
  },
  {
    id: "10",
    name: "Etc",
    avatarUrl:
      "https://cget.tango.me/contentserver/download/AAFn3B2VAABs0-FtJjvgkpi2AAAAAMasJJM/OEpcsnU0/thumbnail",
    badgeType: BadgeType.NONE,
    vipLabel: VipLabel.LEGEND,
    streamId: "BL9atY7ieAagd1ar8xZWLQ",
  },
];

export const MESSAGE_MENU_ANIMATION_DURATION = 300;
