import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { datadogLogs } from "@datadog/browser-logs";
import Cookies from "js-cookie";
import { MarketingClientVisitEvent } from "generated/proto/MarketingClientVisitEventModel";
import {
  FACEBOOK_C_COOKIE_NAME,
  FACEBOOK_P_COOKIE_NAME,
  SNAPCHAT_COOKIE_NAME,
} from "src/constants";
import { sendVisitMarketing } from "src/features/marketing/api/marketing";
import {
  AdditionalDataPoints,
  DEFAULT_SESSION_ID,
  EventNames,
  MARKETING_PLATFORM,
  QueryClickId,
  RootState,
  UTM_PARAMS,
  UtmParams,
  afParameters,
  cleanSearchParamsStorageByKeys,
  deviceInfoSelectors,
  searchParamsStorage,
  serverOwnedConfigSelectors,
  setAppsFlyerDirectLinkURL,
  setAppsFlyerOneLinkURL,
} from "src/features/marketing/imports";
import { useIsPageForVisitor } from "src/features/marketing/imports/hooks";
import {
  getGooglePlayApkLink,
  getOneLinkTemplate,
} from "src/features/marketing/imports/state";
import {
  dataMeshTrackEvent,
  omitUndefinedProps,
} from "src/features/marketing/imports/utils";
import { marketingSelectors } from "src/features/marketing/state/selectors";
import { setMarketingVisitData } from "src/features/marketing/state/slice";
import { handleSendFirstVisitEvent } from "src/features/marketing/utils/handleSendFirstVisitEvent";

const platformMapping: Record<string, MARKETING_PLATFORM> = {
  IR_CLICK_ID: MARKETING_PLATFORM.IMPACT,
  VOLUUM: MARKETING_PLATFORM.VOLUUM,
  SNAPCHAT: MARKETING_PLATFORM.SNAPCHAT,
  TWITTER: MARKETING_PLATFORM.TWITTER,
  FACEBOOK_CLICK_ID: MARKETING_PLATFORM.FACEBOOK,
  BING_CLICK_ID: MARKETING_PLATFORM.BING,
  GOOGLE_CLICK_ID: MARKETING_PLATFORM.GOOGLE,
  TIKTOK_CLICK_ID: MARKETING_PLATFORM.TIKTOK,
};

const createPlatformPayload = (
  clickIdObject: Record<string, string>,
  platformMapping: Record<string, MARKETING_PLATFORM>
) =>
  Object.entries(clickIdObject).reduce((payload, [key, clickId]) => {
    const platform = platformMapping[key];
    if (platform && clickId) {
      return { ...payload, [platform]: { clickId } };
    }

    return payload;
  }, {});

const selector = (state: RootState) => ({
  visitData: marketingSelectors.getVisitData(state),
  isServerConfigReady: serverOwnedConfigSelectors.getIsServerConfigReady(state),
  fingerprint: deviceInfoSelectors.getDeviceFingerprint(state),
  analyticsKey: marketingSelectors.getAnalyticsKey(state),
  oneLinkTemplate: getOneLinkTemplate(state),
  googlePlayApkLink: getGooglePlayApkLink(state),
});

type GeneralParams =
  | {
      [MARKETING_PLATFORM.GENERAL]: {
        landing_url: string;
        referrer_url: string;
      };
    }
  | NonNullable<unknown>;

const useVisitMarketing = () => {
  const {
    visitData,
    isServerConfigReady,
    fingerprint,
    analyticsKey,
    oneLinkTemplate,
    googlePlayApkLink,
  } = useSelector(selector, shallowEqual);

  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(window.location.search);

  const isVisitorPath = useIsPageForVisitor();

  useEffect(() => {
    if (analyticsKey && window?.AF_SMART_SCRIPT && isServerConfigReady) {
      const setAppsFlyerLinks = async () => {
        const sessionIdValue =
          (await searchParamsStorage.getItem(
            AdditionalDataPoints.SESSION_ID
          )) || DEFAULT_SESSION_ID;

        const afParametersWithAnalyticsKey = {
          googlePlayApkLink,
          oneLinkURL: oneLinkTemplate,
          afParameters: {
            ...afParameters,
            afCustom: [
              ...afParameters.afCustom,
              {
                paramKey: AdditionalDataPoints.SESSION_ID,
                keys: [AdditionalDataPoints.SESSION_ID],
                defaultValue: sessionIdValue,
              },
              {
                paramKey: AdditionalDataPoints.ANALYTICS_ID,
                keys: [AdditionalDataPoints.ANALYTICS_ID],
                defaultValue: analyticsKey,
              },
            ],
          },
        };

        await setAppsFlyerOneLinkURL(afParametersWithAnalyticsKey);
        await setAppsFlyerDirectLinkURL(afParametersWithAnalyticsKey);
      };

      setAppsFlyerLinks();
    }
  }, [analyticsKey, window?.AF_SMART_SCRIPT, isServerConfigReady]);

  const getSearchParams = async () => {
    const clickIdObject: Record<string, string> = {};

    for (const [key, value] of Object.entries(QueryClickId)) {
      const clickId =
        (await searchParamsStorage.getItem(value)) || searchParams.get(value);
      if (clickId) {
        clickIdObject[key as keyof typeof QueryClickId] = clickId;
      }
    }

    const landing_url =
      (await searchParamsStorage.getItem(AdditionalDataPoints.LANDING_URL)) ||
      window.location.href;
    const referrer_url =
      (await searchParamsStorage.getItem(AdditionalDataPoints.REFERRER_URL)) ||
      document.referrer ||
      undefined;
    const flirtyLevel =
      (await searchParamsStorage.getItem(AdditionalDataPoints.MARKETING_FL)) ||
      searchParams.get(AdditionalDataPoints.MARKETING_FL) ||
      undefined;
    const snapchatCookie =
      (await searchParamsStorage.getItem(
        AdditionalDataPoints.SNAPCHAT_COOKIE_ID
      )) || Cookies.get(SNAPCHAT_COOKIE_NAME);
    const fbcCookie =
      (await searchParamsStorage.getItem(
        AdditionalDataPoints.FACEBOOK_C_COOKIE
      )) || Cookies.get(FACEBOOK_C_COOKIE_NAME);
    const fbpCookie =
      (await searchParamsStorage.getItem(
        AdditionalDataPoints.FACEBOOK_P_COOKIE
      )) || Cookies.get(FACEBOOK_P_COOKIE_NAME);

    const utmParams = (await UTM_PARAMS.reduce(async (accPromise, curr) => {
      const acc = await accPromise;
      const param =
        (await searchParamsStorage.getItem(curr)) || searchParams.get(curr);
      if (param) {
        return { ...acc, [curr]: param };
      }

      return acc;
    }, Promise.resolve({}))) as Record<UtmParams, string>;

    return {
      searchParams,
      clickIdObject,
      landing_url,
      referrer_url,
      flirtyLevel,
      snapchatCookie,
      fbcCookie,
      fbpCookie,
      utmParams,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      const {
        clickIdObject,
        landing_url,
        referrer_url,
        flirtyLevel,
        snapchatCookie,
        fbcCookie,
        fbpCookie,
        utmParams,
      } = await getSearchParams();

      const additionalMarketingParams: GeneralParams = {
        [MARKETING_PLATFORM.GENERAL]: {
          ...(Object.keys(utmParams).length !== 0
            ? {
                ...utmParams,
              }
            : {}),
          analyticsKey,
          snapchatCookie,
          fbcCookie,
          fbpCookie,
          flirtyLevel,
          landing_url,
          snapchatUserAgent: window.navigator.userAgent,
          referrer_url,
        },
      };

      if (visitData.isVisitRequestNeeded && isServerConfigReady) {
        const platformPayload = omitUndefinedProps({
          ...createPlatformPayload(clickIdObject, platformMapping),
          ...additionalMarketingParams,
        });

        if (Object.keys(platformPayload).length !== 0) {
          sendVisitMarketing({
            body: { deviceId: fingerprint, platformPayload },
            isVisitorPath,
          })
            .then(() => {
              const attributedParams = {
                analyticsKey,
                landingUrl: landing_url,
                referrerUrl: referrer_url,
                utmSource: utmParams[UtmParams.UTM_SOURCE],
                utmMedium: utmParams[UtmParams.UTM_MEDIUM],
                utmCampaign: utmParams[UtmParams.UTM_CAMPAIGN],
              };

              const clickId = Object.values(clickIdObject).join(",");

              handleSendFirstVisitEvent({
                ...attributedParams,
                clickId,
              });

              const reportedUrlParams = Object.fromEntries(
                searchParams.entries()
              );

              const visitEventBody = {
                ...attributedParams,
                reportedUrlParams,
              };

              dataMeshTrackEvent({
                eventName: EventNames.MARKETING_CLIENT_VISIT,
                ProtoSchema: MarketingClientVisitEvent,
                body: visitEventBody,
              });
            })
            .catch((error) => {
              datadogLogs.logger.warn(
                error?.message,
                { kind: "marketing_visit" },
                error
              );
            })
            .finally(() => {
              cleanSearchParamsStorageByKeys([
                QueryClickId.IR_CLICK_ID,
                QueryClickId.VOLUUM,
                QueryClickId.TWITTER,
                QueryClickId.FACEBOOK_CLICK_ID,
                QueryClickId.BING_CLICK_ID,
                QueryClickId.GOOGLE_CLICK_ID,
                QueryClickId.SNAPCHAT,
                QueryClickId.TIKTOK_CLICK_ID,
                AdditionalDataPoints.SESSION_ID,
                AdditionalDataPoints.LANDING_URL,
                AdditionalDataPoints.FACEBOOK_C_COOKIE,
                AdditionalDataPoints.FACEBOOK_P_COOKIE,
                AdditionalDataPoints.REFERRER_URL,
                AdditionalDataPoints.SNAPCHAT_COOKIE_ID,
                ...UTM_PARAMS,
              ]);
              dispatch(
                setMarketingVisitData({
                  isVisitRequestNeeded: false,
                })
              );
            });
        }
      }
    };

    fetchData();
  }, [isServerConfigReady, visitData, isVisitorPath]);
};

export default useVisitMarketing;
