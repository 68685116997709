import React, { FC, memo } from "react";
import classnames from "classnames";
import { LiveBarDesktopSkeleton } from "chat/components/common/LiveBarSkeleton/LiveBarSkeleton";
import { Breakpoints } from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import styles from "./ConversationsListSkeleton.scss";

const REPEATED_ITEMS_COUNT = 9;

interface ConversationsListSkeletonProps {
  isChatLiveBarEnabled?: boolean;
  withHeader?: boolean;
}

const ConversationsListSkeleton: FC<ConversationsListSkeletonProps> = ({
  withHeader = false,
  isChatLiveBarEnabled,
}) => {
  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  const items = Array.from({ length: REPEATED_ITEMS_COUNT }, (_, i) => (
    <div key={i} className={styles.item}>
      <div className={styles.circle} />
      <div className={styles.info}>
        <div className={styles.title}>
          <div className={styles.name} />
          <div className={styles.date} />
        </div>
        <div className={styles.message} />
      </div>
    </div>
  ));

  return (
    <div
      className={classnames(styles.root, styles[breakpoint], {
        [styles.withHeader]: withHeader,
      })}
    >
      <div className={styles.overlay} />
      {isChatLiveBarEnabled && isDesktop && <LiveBarDesktopSkeleton />}
      {items}
    </div>
  );
};

export default memo(ConversationsListSkeleton);
