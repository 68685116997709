import React, { useCallback, useEffect, useState } from "react";
import useDocumentVisibilityState from "ui/hooks/useDocumentVisibilityState";
import useVideoPlaybackState, {
  IdleState,
  PausedState,
  PlayingState,
  describeState,
} from "ui/hooks/useVideoPlaybackState";
import logger from "utils/logger";

export const useLiveVideoPlay = ({
  muted,
  paused,
  videoRef,
}: {
  muted: boolean;
  paused: boolean;
  videoRef: React.RefObject<HTMLVideoElement | null>;
}) => {
  // NOTE: Temporarily use local state to keep user interaction request.
  const [
    isUserInitiatedPlayRequestRequired,
    setUserInitiatedPlayRequestRequired,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ] = [false, (_value: boolean) => {}];

  const [isMuted, setMuted] = useState<boolean>(muted);
  const { visibilityState } = useDocumentVisibilityState();
  const { playbackState } = useVideoPlaybackState(videoRef);

  const startPlayback = useCallback(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    if (paused) {
      logger.log(
        `[LiveVideoPlay]::[startPlayback]: skip start playback due to paused state`
      );

      return;
    }

    logger.log(
      `[LiveVideoPlay]::[startPlayback]: isMuted=${isMuted} - attempting to start playback`
    );

    // NOTE: Do not use async function here
    const playResult: Promise<void> | undefined = video.play();
    if (typeof playResult === "undefined") {
      return;
    }
    playResult
      .then(() => {
        logger.log(
          `[LiveVideoPlay]::[startPlayback]: isMuted=${isMuted}, isUserInitiatedPlayRequestRequired=${isUserInitiatedPlayRequestRequired} - video playback started`
        );
      })
      .catch((error) => {
        logger.log(
          `[LiveVideoPlay]::[startPlayback]: isMuted=${isMuted}, isUserInitiatedPlayRequestRequired=${isUserInitiatedPlayRequestRequired} - video play start error`,
          error
        );
        if (error instanceof DOMException) {
          if (error.name === "NotAllowedError") {
            setMuted(true);
            if (isMuted) {
              logger.log(
                `[LiveVideoPlay]::[startPlayback]: isMuted=${isMuted}, isUserInitiatedPlayRequestRequired=${isUserInitiatedPlayRequestRequired} - need user interaction to play`
              );
              setUserInitiatedPlayRequestRequired(true);
            } else {
              logger.log(
                `[LiveVideoPlay]::[startPlayback]: isMuted=${isMuted}, isUserInitiatedPlayRequestRequired=${isUserInitiatedPlayRequestRequired} - will try with muted video`
              );
            }
          }
        }
      });
  }, [
    videoRef,
    isMuted,
    paused,
    isUserInitiatedPlayRequestRequired,
    setUserInitiatedPlayRequestRequired,
  ]);

  const stopPlayback = useCallback(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    if (!paused) {
      logger.log(
        `[LiveVideoPlay]::[stopPlayback]: skip stop playback due to unpaused state`
      );

      return;
    }

    if (playbackState !== PlayingState) {
      logger.log(
        `[LiveVideoPlay]::[stopPlayback]: video playback is not active`
      );

      return;
    }

    logger.log(`[LiveVideoPlay]::[stopPlayback]: will pause video playback`);
    video.pause();
  }, [videoRef, paused, playbackState]);

  useEffect(() => {
    if (!paused) {
      return;
    }
    stopPlayback();
  }, [playbackState, paused, stopPlayback]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    if (visibilityState === "hidden") {
      logger.log(
        "[LiveVideoPlay]: skip effect in case of document is not visible"
      );

      return;
    }

    if (isUserInitiatedPlayRequestRequired) {
      logger.log(
        `[LiveVideoPlay]: isMuted=${isMuted}, isUserInitiatedPlayRequestRequired=${isUserInitiatedPlayRequestRequired} need user interaction`
      );

      return;
    }

    if (playbackState === IdleState || playbackState === PausedState) {
      startPlayback();
    }
  }, [
    videoRef,
    startPlayback,
    playbackState,
    isMuted,
    isUserInitiatedPlayRequestRequired,
    visibilityState,
  ]);

  useEffect(() => {
    logger.log(`[LiveVideoPlay]: external muted state: ${muted}`);
    setMuted(muted);
  }, [muted, setMuted]);

  useEffect(() => {
    logger.log(
      `[LiveVideoPlay]: document visibility state: ${visibilityState}`
    );
  }, [visibilityState]);

  return {
    isMuted,
    playbackState: describeState(playbackState),
  };
};
