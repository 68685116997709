import { sendEventToGateway } from "src/core/analytics/dataMesh/api/dataMeshEventsApi";
import { SendEventUtilOptions } from "src/core/analytics/dataMesh/common/types";
import { clearEventBuffer } from "src/core/analytics/dataMesh/utils/bufferMethods/clearEventBuffer";
import { getIsLoggedIn } from "src/core/analytics/dataMesh/utils/store/getStoreEntity";

interface Payload {
  event: string;
  properties: {
    body: BodyInit;
    options?: SendEventUtilOptions;
  };
  type: string;
}

export const dataMeshPlugin = () => ({
  NAMESPACE: "data-mesh-plugin",
  track: async ({ payload }: { payload: Payload }) => {
    sendEventToGateway({
      isUserLoggedIn: getIsLoggedIn(),
      body: payload.properties.body,
    }).then(() => {
      if (payload.properties?.options?.isBufferFlushNeeded) {
        clearEventBuffer();
      }

      payload.properties?.options?.onSuccessCallback?.();
    });
  },
  loaded: () => true,
});
